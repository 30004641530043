<template>
  <div
    v-if="fieldwork"
    aria-labelledby="fieldworks edit page"
    ref="fieldworksEditPage"
  >
    <div class="header-text" data-cy="header-fieldworks-edit">
      Edit {{ form_title }}
    </div>
    <StudentEntryForm
      :form_type="form_title.toLowerCase()"
      :is_private="show_confidentiality"
      :form_fields="form_fields"
      :field_values="field_values"
      isUpdate="true"
      :notes="fieldwork.notes"
      :confidential="fieldwork.confidential"
      @submit="submitForm"
      @cancel="cancelForm"
      :submitted="submitted"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, useStore } from "vuex";
import StudentEntryForm from "@/components/forms/StudentEntryForm";
export default {
  name: "FieldworksEdit",
  components: { StudentEntryForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (
      this.$store.state.user_selected_term.name !==
      this.lookup_current_term.name
    ) {
      this.$router.push("/student/fieldworks/{id}");
    }

    const store = useStore();
    // This will be need to be moved to the state as an action
    // Pass the prop id to endpoint GET /student/fieldworks/{id}
    let loadFieldwork = (id) => {
      return store.state.fieldwork_form_store.entries.find(
        (fieldwork) => fieldwork.fieldwork_id === id
      );
    };
    if (store.state.fieldwork_form_store.entries.length <= 0) {
      store.dispatch("getFieldworkEntries").then(() => {
        this.fieldwork = loadFieldwork(this.$route.params.id);
        // this.$refs.fieldworksEditPage.focus();
      });
    } else {
      this.fieldwork = loadFieldwork(this.$route.params.id);
      // this.$refs.fieldworksEditPage.focus();
    }
  },
  data: function () {
    return {
      fieldwork: null,
      submitted: false,
    };
  },
  methods: {
    submitForm: function (form) {
      this.submitted = true;

      form.fields.forEach((field) => {
        if (field.value instanceof Array) {
          field.value = field.value.join(",");
        }
      });
      this.fieldwork.confidential = form.confidential;
      this.fieldwork.description = form.description;
      this.fieldwork.id = this.$route.params.id;
      this.fieldwork.fields = form.fields;
      this.fieldwork.event_status = 'TODO';
      this.fieldwork.fieldwork_date = form.fieldwork_date
      this.$store
        .dispatch("putFieldworkEntry", {
          data: this.fieldwork,
          student_at_level_id: this.getAcadLevel.student_at_level_id,
        })
        .then(() => {
          this.submitted = false;
          this.$router
            .push({
              path: "/fieldworks/add/success",
              query: { entry_type: this.form_title, isUpdate: true },
            })
            .catch((err) => {
              this.submitted = false;
              console.log("error: putFieldworkEntry):", err);
            });
        });
    },
    cancelForm: function () {
      this.$router.go(-1);
    },
    ...mapActions(["getFieldworkEntries"]),
  },
  computed: {
    ...mapState({
      forms: (state) => state.lookup_data.fieldwork_types,
    }),
    ...mapGetters(["student_profile", "getAcadLevel", "lookup_current_term"]),
    form_title: function () {
      return this.forms.find(
        (form) =>
          form.fieldwork_log_type_id === this.fieldwork?.fieldwork_type_id
      ).fieldwork_type_name;
    },
    form_fields: function () {
      // return fields of form
      let fields = this.forms.find(
        (form) =>
          form.fieldwork_log_type_id === this.fieldwork.fieldwork_type_id
      ).fields;
      // sort fields by display_order property
      fields.sort((a, b) => a.display_order - b.display_order);
      return fields;
    },
    show_confidentiality: function () {
      // return fields of form
      return this.forms.find(
        (form) =>
          form.fieldwork_log_type_id === this.fieldwork.fieldwork_type_id
      ).show_confidentiality;
    },
    field_values: function () {
      // return values of this.fieldwork from fields object
      let values = [];
      this.fieldwork.fields.forEach((field) => {
        values.push({
          field_id: field.fieldwork_log_field_id,
          field_value: field.value,
        });
      });
      return values;
    },
  },
};
</script>

<style scoped></style>
